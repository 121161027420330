import { PortalCustomerConfiguration } from '../../admin/customers/model/portal-customer-configuration.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageResponseDto } from '../model/page-response.dto';
import { CustomerConfigurationQueryDto } from 'src/app/admin/customers/model/customer-configuration-query.model';
const portalApiUrl = environment.portalApiUrl;

@Injectable({
  providedIn: 'root'
})
export class PortalCustomerConfigurationDataService {

  constructor(private httpClient: HttpClient) { }

  getForUser(isGroupCustomer: boolean): Observable<any> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<any>(`${portalApiUrl}/customerconfiguration/getforuser`, { headers: headers });
  }

  get(customerCode: string): Observable<PortalCustomerConfiguration> {
    return this.httpClient.get<PortalCustomerConfiguration>(`${portalApiUrl}/customerconfiguration/get?code=${customerCode}`);
  }

  getAll(query: CustomerConfigurationQueryDto): Observable<PageResponseDto<PortalCustomerConfiguration>> {
    return this.httpClient.post<PageResponseDto<PortalCustomerConfiguration>>(`${portalApiUrl}/customerconfiguration/getall`, query);
  }

  save(config: PortalCustomerConfiguration): Observable<PortalCustomerConfiguration> {
    return this.httpClient.post<PortalCustomerConfiguration>(`${portalApiUrl}/customerconfiguration/save`, config);
  }

  create(config: PortalCustomerConfiguration): Observable<PortalCustomerConfiguration> {
    return this.httpClient.post<PortalCustomerConfiguration>(`${portalApiUrl}/customerconfiguration/create`, config);
  }

  getGroupByChild(code?: string): Observable<PortalCustomerConfiguration> {
    return this.httpClient.get<PortalCustomerConfiguration>(`${portalApiUrl}/customerconfiguration/getgroupbychild?customerCode=${code}`);
  }

  getDefault(customerConfiguration?: PortalCustomerConfiguration): Observable<PortalCustomerConfiguration> {
    return this.httpClient.post<PortalCustomerConfiguration>(`${portalApiUrl}/customerconfiguration/getdefault`, customerConfiguration);
  }
}
