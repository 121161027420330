import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PortalCustomerConfiguration } from 'src/app/admin/customers/model/portal-customer-configuration.model';
import { PortalCustomerConfigurationDataService } from '../../shared/services/portal-customer-configuration-data.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PortalCustomerConfigurationService {

  private customerConfigurationSubject = new BehaviorSubject<PortalCustomerConfiguration | null>(null);
  customerConfiguration$: Observable<PortalCustomerConfiguration | null>;
  private isLoadingCustomerConfiguration = false;

  constructor(
    private customerConfigurationPortalDataService: PortalCustomerConfigurationDataService,
    private userService: UserService
  ) {
    this.customerConfiguration$ = new Observable((observer) => {
      if (!this.customerConfigurationSubject.value && !this.isLoadingCustomerConfiguration) {
        this.updateDashboardConfiguration();
      }
      return this.customerConfigurationSubject.asObservable().pipe(filter(x => !!x)).subscribe(observer);
    });
  }

  public updateDashboardConfiguration() {
    this.isLoadingCustomerConfiguration = true;
    return this.userService.userProfile$.pipe(
      filter(u => !!u),
      switchMap(user => this.customerConfigurationPortalDataService.getForUser(user!.isCustomerGroup))
    ).subscribe(conf => {
      this.isLoadingCustomerConfiguration = false;
      this.customerConfigurationSubject.next({
        ...conf,
      } as PortalCustomerConfiguration);
    });
  }
}
